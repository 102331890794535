import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FileService } from 'src/app/services/file.service';
import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileUpload } from 'src/app/models/files/FileUpload';
import { FileUploadStatus } from 'src/app/models/files/FileUploadStatus';
import { UploadStatus } from 'src/app/models/files/UploadStatus';
import { FileUploadError } from 'src/app/models/errors/FileUploadError';
import Utils from 'src/app/utils/utils';
import FileUtils from 'src/app/utils//file-utils';
import SizeUtils from 'src/app/utils/size-utils';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() maxFiles: number = 3;
  @Input() maxFileSize: number = 10; // Megabytes
  @Input() allowedFileTypes: string[] = [".gif", ".jpg", ".jpeg", ".png", ".doc", ".docx", ".pdf"];
  @Output() uploadStatusChangeEvent = new EventEmitter<FileUploadStatus>();
  private fileDropzone: Dropzone;
  private fileUploadStatus: FileUploadStatus = new FileUploadStatus(UploadStatus.IN_PROGRESS);
  private uploads: FileUpload[] = [];
  private error: boolean = false;
  private errorMessage: string;
  private maxFileSizeInBytes: number = SizeUtils.megabytesToBytes(this.maxFileSize);

  constructor(private fileService: FileService) { }

  ngOnInit(): void {
    this.initFileDropzone();
  }

  initFileDropzone() {
    this.fileDropzone = new Dropzone(document.getElementById("dropzone-multiple"), {
      autoProcessQueue: false,
      url: "https://",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName("dz-preview-multiple")[0],
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0].innerHTML,
      acceptedFiles: Utils.commaSeparatedList(this.allowedFileTypes)
    });
    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
    this.setFileDropzoneHandlers();
  }

  setFileDropzoneHandlers() {
    this.fileDropzone.on("addedfile", (file: File) => {
      this.uploadFile(file);
    });

    this.fileDropzone.on("removedfile", (file: File) => {
      this.removeUpload(file);
      this.updateUploadStatus();
      this.validateNumberOfFiles();
    });
  }

  uploadFile(file: File) {
    this.error = false;

    try {
      this.validateFile(file);
    } catch (err) {
      this.handleError(file, err);
      return;
    }

    const upload = new FileUpload(file, UploadStatus.IN_PROGRESS);
    this.uploads.push(upload);
    this.updateUploadStatus();
    this.validateNumberOfFiles();
    this.fileService.uploadFile(file).subscribe((uploadedFile: UploadedFile) => {
      upload.uploadedFile = uploadedFile;
      upload.status = UploadStatus.SUCCESSFUL;
      this.updateUploadStatus();
    },
    err => {
      upload.status = UploadStatus.FAILED;
      this.handleError(file, err);
    });
  }

  validateFile(file: File) {
    if (file.size > this.maxFileSizeInBytes) {
      throw new FileUploadError(`${file.name} exceeds the max file size`);
    } else if (!this.isAllowedFileType(file)) {
      throw new FileUploadError(`${file.name} must be one of the allowed file types`);
    }
  }

  isAllowedFileType(file: File): boolean {
    const extension = FileUtils.getExtensionWithDot(file.name);
    return this.allowedFileTypes.includes(extension);
  }

  validateNumberOfFiles() {
    if (this.uploads.length >= this.maxFiles) {
      this.fileDropzone.disable();
    } else if (this.uploads.length < this.maxFiles) {
      this.fileDropzone.enable();
    }
  }

  handleError(file: File, error: any) {
    if (error instanceof FileUploadError) {
      this.errorMessage = error.message;
    } else {
      this.errorMessage = "Sorry, one or more files failed to upload. If the issue persists, please try again later."
    }
    this.error = true;
    this.removeFileFromFileDropzone(file);
  }

  updateUploadStatus() {
    let statuses: UploadStatus[] = this.uploads.map((upload) => upload.status);
    let currentStatus = UploadStatus.IN_PROGRESS;
    if (this.isUploadComplete(statuses)) {
      currentStatus = UploadStatus.SUCCESSFUL;
    }
    if (this.fileUploadStatus.status !== currentStatus ||
        this.fileUploadStatus.uploadedFiles !== this.uploads) {
      this.fileUploadStatus.status = currentStatus;
      this.fileUploadStatus.uploadedFiles = this.uploads;
      this.uploadStatusChangeEvent.emit(this.fileUploadStatus);
    }
  }

  isUploadComplete(statuses: UploadStatus[]) {
    return statuses.length === statuses.filter((status) => status === UploadStatus.SUCCESSFUL).length;
  }

  removeFileFromFileDropzone(file: File) {
    this.fileDropzone.removeFile(file);
    this.removeUpload(file);
  }
  
  removeUpload(file: File) {
    const uploadedFile = this.uploads.filter(upload => upload.file === file)[0]?.uploadedFile;
    this.uploads = this.uploads.filter((upload) => upload.file !== file);
    if (uploadedFile) {
      this.fileService.deleteFile(uploadedFile.id).subscribe(() => {});
    }
  }
}
