import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { FileUploadStatus } from 'src/app/models/files/FileUploadStatus';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { UploadStatus } from 'src/app/models/files/UploadStatus';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<UploadedFile[]>();
  private fileUploadStatus: FileUploadStatus;

  constructor() { }

  ngOnInit() {
  }

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickDone() {
    let uploadedFiles: UploadedFile[] = this.fileUploadStatus.uploadedFiles
      .map(uploadedFile => uploadedFile.uploadedFile)
    this.doneEvent.emit(uploadedFiles);
  }

  onUploadStatusChange(fileUploadStatus: FileUploadStatus) {
    this.fileUploadStatus = fileUploadStatus;
  }

  isUploadInProgress(): boolean {
    return this.fileUploadStatus?.status === UploadStatus.IN_PROGRESS;
  }

}
