import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { VehicleSettingsService as Service } from 'src/app/models/VehicleSettingsService';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { AuthService } from 'src/app/services/auth.service';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import StringUtils from 'src/app/utils/string-utils';
import { VehicleSettings } from 'src/app/models/VehicleSettings';
import { AlertService } from 'src/app/services/alert.service';

const DEFAULT_VEHICLE_DROPDOWN_OPTION = "Select a vehicle";

@Component({
  selector: 'app-services-settings',
  templateUrl: './services-settings.component.html',
  styleUrls: ['./services-settings.component.scss']
})
export class ServicesSettingsComponent implements OnInit {
  private addServiceModal: BsModalRef;
  private formDisabled: boolean = false;
  private vehicleDropdownValue: string = DEFAULT_VEHICLE_DROPDOWN_OPTION;
  private selectedService: Service;
  private userVehicles: UserVehicle[] = [];
  private vehicleSettings: VehicleSettings = new VehicleSettings();

  constructor(
    private userVehicleService: UserVehicleService,
    private authService: AuthService,
    private modalService: BsModalService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    const userVehicles = this.userVehicleService.getUserVehiclesFromLocalStorage();
    if (userVehicles) {
      this.userVehicles = userVehicles;
      this.getCurrentVehicleAndPopulateForm();
    } else {
      this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
        this.userVehicles = userVehicles;
        this.getCurrentVehicleAndPopulateForm();
      });
    }
  }

  getCurrentVehicleAndPopulateForm() {
    const currentVehicle = this.userVehicleService.getCurrentVehicle();
    if (currentVehicle) {
      this.getVehicleSettingsAndPopulateForm(currentVehicle.id);
    } else {
      this.formDisabled = true;
    }
  }

  getVehicleSettingsAndPopulateForm(userVehicleId: string) {
    this.vehicleDropdownValue = userVehicleId;
    this.getVehicleSettings(userVehicleId);
  }

  getVehicleSettings(userVehicleId: string) {
    this.userVehicleService.getSettings(userVehicleId).subscribe(settings => {
      this.vehicleSettings = settings;
      this.formDisabled = false;
    });
  }

  onChangeSelectedVehicle() {
    if (this.vehicleDropdownValue === DEFAULT_VEHICLE_DROPDOWN_OPTION) {
      this.vehicleSettings = new VehicleSettings();
      this.formDisabled = true;
      return;
    }
    this.getVehicleSettingsAndPopulateForm(this.vehicleDropdownValue);
  }

  onClickService(service: Service, modal: TemplateRef<any>) {
    this.selectedService = service;
    this.addServiceModal = this.modalService.show(modal, {
      keyboard: true,
      backdrop: 'static',
      class: "modal-dialog-centered"
    });
  }

  onClickDeleteService(service: Service, event: any) {
    this.preventSelectionOfServiceWhenClickingOnButton(event);
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.deleteService(service);
          this.updateVehicleSettingsServices(() => {
            this.alertService.showDeleteConfirmation("This service has been deleted.");
          });
        }
      });
  }

  onClickAdd(modal: TemplateRef<any>) {
    this.addServiceModal = this.modalService.show(modal, {
      keyboard: true,
      backdrop: 'static',
      class: "modal-dialog-centered"
    });
  }

  onClickCloseAddServiceModal() {
    this.hideAddServicesModal();
  }

  onDoneAddingService(service: Service) {
    this.vehicleSettings.services.push(service);
    this.updateVehicleSettingsServices(() => {
      this.hideAddServicesModal();
    });
  }

  onDoneUpdatingService(service: Service) {
    const serviceToUpdate = this.vehicleSettings.services.find(s => s.name === service.name);
    var indexOfServiceToUpdate = this.vehicleSettings.services.indexOf(serviceToUpdate);
    this.vehicleSettings.services[indexOfServiceToUpdate] = service;
    this.updateVehicleSettingsServices(() => {
      this.hideAddServicesModal();
    });
  }

  updateVehicleSettingsServices(cb: any) {
    this.userVehicleService.updateServices(this.vehicleSettings.id,
      this.vehicleSettings.services).subscribe(() => {
        cb();
        this.getVehicleSettings(this.vehicleSettings.id);
      });
  }

  deleteService(service: Service) {
    this.vehicleSettings.services = this.vehicleSettings.services
      .filter(s => s.name !== service.name);
  }

  hideAddServicesModal() {
    if (this.addServiceModal) {
      this.addServiceModal.hide();
    }
  }

  getReadableFrequencyText(service: Service) {
    let text = "";
    if (service.frequencyInTimeEnabled && service.frequencyInMilesEnabled) {
      text += this.getReadableTimeFrequencyText(service) + ", or " + this.getReadableMileageFrequencyText(service);
    } else if (service.frequencyInTimeEnabled) {
      text += this.getReadableTimeFrequencyText(service);
    } else if (service.frequencyInMilesEnabled) {
      text += this.getReadableMileageFrequencyText(service);
    }
    return StringUtils.capitalizeFirstLetter(text);
  }

  getReadableTimeFrequencyText(service: Service) {
    if (service.frequencyInTime === 1) {
      return "every " + service.temporalUnit.toString().toLowerCase();
    } else if (service.frequencyInTime > 1) {
      return "every " + service.frequencyInTime + " " + service.temporalUnit.toString().toLowerCase() + "s";
    }
  }

  getReadableMileageFrequencyText(service: Service) {
    return "every " + StringUtils.formatNumber(service.frequencyInMiles) + " miles";
  }

  getVehicleDropdownDefaultValue() {
    return DEFAULT_VEHICLE_DROPDOWN_OPTION;
  }

  preventSelectionOfServiceWhenClickingOnButton(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

}
